import { Layout, notification, Space } from 'antd';
import React, { lazy } from 'react';
import { useParams } from 'react-router-dom';

import { asyncWrap } from '../api';
import Break from '../components/Break';
import Loader from '../components/Loader';
import timeUtils from '../helpers/timeUtils';
import userUtils, { adminUtils } from '../helpers/userUtils';
import utils from '../helpers/utils';
import { User } from '../types';
import { Role } from '../types/acl';
import ContextProvider from './useContext';
import updateHS from './useHS';
import usePageViews from './UsePageViews';
import aURL from '@/helpers/url';
import { setUser as setSentryUser } from '@sentry/react';

const WarningBanner = lazy(() => import('../components/error/WarningBanner'));
const SandboxTermsDrawer = lazy(() => import('../components/SandboxTermsDrawer'));
const AdminBanner = lazy(() => import('../pages/admin/AdminBanner'));
const AlertAboutRefreshModal = lazy(() => import('../pages/settings/textingSettings/components/modals/AlertAboutRefreshModal'));
const NotificationsDrawer = lazy(() => import('../pages/alerts/components/NotificationsDrawer'));
const FeatureHighlightDrawer = lazy(() => import('../pages/alerts/components/FeatureHighlightDrawer'));
const ProbationAndInvoiceFreezeModal = lazy(() => import('../components/error/InvoiceFreezeModal'));
const ManageAlerts = lazy(() => import('@/pages/alerts/alertsManager/ManageAlerts'));
const NavWrapper = lazy(() => import('@/components/navigation/NavWrapper'));
const ErrorBoundary = lazy(() => import('@/components/error/ErrorBoundary'));

// This is a wrapper for components that require authentication. if not logged in, it will redirect to the login page
// noLayout is used for the login page, which doesn't need the navbar
export default function useAuthentication(AuthComponent: any, noLayout = false) {
	const { auth } = utils;
	// This is the actual wrapper
	return function AuthWrapper(props: any) {
		usePageViews();
		const params = useParams<{ uid: string }>();

		const isStaffAppPage = utils.strify(location.pathname).includes('walletsearch');

		const [loaded, setLoaded] = React.useState(false);
		const [uid, _] = React.useState<string | undefined>(params.uid || utils.auth.getUID() || '1');
		const [user, setUser] = React.useState<User | undefined>(undefined);

		const sendToLogin = (withPath?: boolean) => {
			const path = withPath ? location.pathname + location.search : '';
			new aURL('/login').appendSearchParam('p', path).open();
		};

		React.useEffect(() => {
			// If the user is not logged in
			if (!loaded)
				asyncWrap(
					async () => {
						// If the user is not logged in, we redirect to the login page
						if (!auth.loggedIn()) {
							return new aURL(utils.home()).open();
						}

						// Get user from API
						const user: User | undefined = (await utils.auth.getUser(uid)) || undefined;
						if (!user) {
							throw new Error(`User not found: ${uid}`);
						}

						asyncWrap(async () => {
							const rid = auth.getUID();

							// If this is not an agency log last UID for off change of pageNotFound
							if (!!user.agencyID || user.type !== 'agency') utils.local.setWithExpiry('lastUID', uid, timeUtils.units.MINUTE_MS * 5);

							utils.uid = uid ?? '';
							utils.user = user;
							utils.realID = rid;
							utils.history = props.history;
							const match = props.match;
							utils.params = !!match && !!match.params ? match.params : {};
						});

						// If the user is an admin who is not whitelisted, we remove the HS script
						if (utils.isAdmin() && !adminUtils.hubspotWhitelist()) {
							utils.removeScript('hs-script-loader', true);
						} else {
							updateHS(user);
						}

						const email = utils.auth.getEmail();
						const role: Role | undefined = utils.auth.getRole();
						// Perform action based on the user's role
						switch (role) {
							case Role.BUDTENDER:
								if (!isStaffAppPage) new aURL(utils.home()).open();
								break;
							case Role.FINANCIAL:
								// If the user is a financial admin, we redirect them to the financial reporting page
								if (!location.pathname.startsWith(`/settings/${user?.id || uid}/loyalty/reporting`)) {
									new aURL(utils.home()).open();
								}
								break;
							case undefined:
								auth.logout();
								const path = location.pathname + location.search;
								sendToLogin(true);
								break;
							default:
								break;
						}

						setUser(user);
						setSentryUser({ uid, role, email });
					},
					{
						finallyCallback(wasError, currentValue) {
							setLoaded(true);
						},
						errorCallback(error: Error) {
							if (userUtils.isAdmin() && error.message.toLowerCase() === `user not found: ${uid}`) {
								utils.home();
								return utils.showErr(error);
							}
							auth.logout();
							sendToLogin(true);
						},
					},
				);
		}, []);

		// If the user is not logged in, we redirect to the login page so return null
		if (!user) return null;

		// If account is on probation && the user is not an admin. They should not be able to access the site
		if ((user.probation || user.invoiceFreeze) && !utils.isAdmin()) {
			return (
				<>
					<Layout
						className="bg"
						style={{ minHeight: '100vh' }}
					>
						<ProbationAndInvoiceFreezeModal user={user} />
					</Layout>
				</>
			);
		}

		// if the account is on probation or is archived we send the admin an alert
		if (user.probation || user.archived || user.invoiceFreeze) {
			const color = '#1890FF';
			const isOn: string[] = [];
			if (user.probation) isOn.push('on probation');
			if (user.invoiceFreeze) isOn.push('on invoice freeze');
			if (user.archived) isOn.push('archived');
			notification['info']({
				duration: 8,
				maxCount: 1,
				style: { borderLeft: color ? `3px solid ${color}` : '' },
				key: 'admin-auth-account-info',
				placement: 'top',
				message: <span style={{ fontSize: '1rem', fontWeight: 600 }}>Account Status</span>,
				description: (
					<>
						<span>{`This account is currently ${isOn.join(', ')}. Clients will not be able to access this account until is reactivated.`}</span>
						<Break air />
						{!!user.probation && (
							<>
								<Space>
									<span style={{ fontWeight: 600 }}>Probation:</span>
									<span>True</span>
								</Space>
								<Break air />
							</>
						)}
						{!!user.invoiceFreeze && (
							<>
								<Space>
									<span style={{ fontWeight: 600 }}>Invoice Freeze:</span>
									<span>True</span>
								</Space>
								<Break air />
							</>
						)}
						{!!user.archived && (
							<>
								<Space>
									<span style={{ fontWeight: 600 }}>Archived:</span>
									<span>True</span>
								</Space>
							</>
						)}
					</>
				),
			});
		}

		const showSandboxTerms = user.subType === 'sandbox' && !user.sandboxTerms?.timestamp;

		return (
			<React.Suspense fallback={<Loader fullPage />}>
				<ContextProvider
					uid={uid ?? ''}
					noLayout={noLayout}
				>
					<React.Suspense fallback={<></>}>{showSandboxTerms && <SandboxTermsDrawer />}</React.Suspense>
					<React.Suspense fallback={<></>}>
						<ManageAlerts />
						<AlertAboutRefreshModal />
						<NotificationsDrawer />
						<FeatureHighlightDrawer />
						<AdminBanner />
						<WarningBanner />
					</React.Suspense>

					<NavigationWrapper noLayout={noLayout}>
						<React.Suspense fallback={<Loader />}>
							<ErrorBoundary>
								<AuthComponent
									asHost={utils.asHost}
									user={user}
									{...props}
								/>
							</ErrorBoundary>
						</React.Suspense>
					</NavigationWrapper>
				</ContextProvider>
			</React.Suspense>
		);
	};
}

function NavigationWrapper({ noLayout, children, ...rest }: { noLayout?: boolean; children: React.ReactNode; user?: User }) {
	if (noLayout) {
		return <>{children}</>;
	}
	return <NavWrapper {...rest}>{children}</NavWrapper>;
}
